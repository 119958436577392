


























































import { Workshop } from "@/models/workshop.model";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import WorkshopReservations from "./WorkshopReservations.vue";

const workshopModule = namespace("Workshops");
const languagesModule = namespace("Languages");

@Component({ components: { WorkshopReservations } })
export default class WorkkshopDetail extends Vue {
  isLoading = false;

  @languagesModule.Getter("all")
  languages!: string[];

  @workshopModule.Getter("current")
  workshop!: Workshop;

  @workshopModule.Action("fetchById")
  fetchWorkshop!: (id: string) => Promise<Workshop>;

  @workshopModule.Action("create")
  create!: (workshop: Workshop) => Promise<Workshop>;

  @workshopModule.Action("update")
  update!: (workshop: Workshop) => Promise<Workshop>;

  get limitNumberOfSeats() {
    return this.workshop.limit_seats;
  }

  get isNewWorkshop() {
    return this.$route.name === "workshop-create";
  }

  async doSave() {
    this.isLoading = true;
    if (this.isNewWorkshop) {
      await this.create(this.workshop);
      this.$router.push({
        name: "workshops",
      });
    } else {
      await this.update(this.workshop);
    }
    this.isLoading = false;
  }

  async doFetchWorkshop() {
    this.isLoading = true;
    if (!this.isNewWorkshop) {
      await this.fetchWorkshop(this.$route.params.id);
    }
    this.isLoading = false;
  }

  created() {
    this.doFetchWorkshop();
  }
}
