









import { WorkshopReservation } from "@/models/workshop-reservation.model";
import { Workshop } from "@/models/workshop.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import BaseTable from "@/components/admin/BaseTable.vue";
import { Column } from "@/shared/types/column.class";

const workshopModule = namespace("Workshops");

@Component({ components: { BaseTable } })
export default class WorkshopReservations extends Vue {
  isLoading = false;
  columns = [
    new Column("user.first_name", "First name"),
    new Column("user.last_name", "Last name"),
    new Column("user.email", "Email"),
    new Column("reservation_status", "Status"),
    new Column("registration_field", "Registration field"),
    new Column("created_at", "Registered at"),
  ];

  @Prop()
  workshop!: string;

  @workshopModule.Getter("reservations")
  workshopReservations!: WorkshopReservation[];

  @workshopModule.Action("fetchReservationsForWorkshop")
  fetchWorkshopReservations!: (id: string) => Promise<Workshop>;

  async doFetchWorkshopReservations() {
    this.isLoading = true;
    console.log(this.workshop);
    await this.fetchWorkshopReservations(this.workshop);
    this.isLoading = false;
  }

  created() {
    this.doFetchWorkshopReservations();
  }
}
